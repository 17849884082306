<template>
  <form @submit.prevent="onSearch">
    <CRow class="align-items-end">
      <CCol md="5">
        <CInput
          label="Cerca"
          :value.sync="filters.search"
          placeholder="Cerca per destinatario"
        >
          <template #prepend-content>
            <font-awesome-icon icon="search" />
          </template>
        </CInput>
      </CCol>
      <CCol md="3">
        <CSelect
          label="Tipologia"
          :value.sync="filters.invoiceType"
          :options="invoiceTypeOptions"
          custom
        />
      </CCol>
      <!-- <CCol col="auto">
        <CButton class="mb-3" color="primary" type="submit">
          <font-awesome-icon icon="search" />
        </CButton>
      </CCol> -->
    </CRow>
  </form>
</template>

<script>
import { getInvoiceTypeOptions } from "../../../../helpers/options";
export default {
  name: "InvoicesOutFilters",

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    const options = [
      { value: "", label: "- Tutti -" },
      ...getInvoiceTypeOptions(),
    ];
    return {
      invoiceTypeOptions: options,
    };
  },

  methods: {
    onSearch() {
      this.$emit("onSearch", { ...this.filters });
    },
  },
};
</script>
