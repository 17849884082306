<template>
  <div>
    <div class="mb-3" v-if="hasAggregatedData">
      <font-awesome-icon icon="money-check-alt" />
      <span class="ml-2 mr-2">Totale fatture da emettere</span>
      <strong>{{ totalToBeIssued() }}</strong>
    </div>

    <div class="table-wrapper">
      <ejs-grid
        ref="grid"
        :dataSource="dm"
        :allowPaging="true"
        :query="query"
        :pageSettings="pageSettings"
        :dataBound="onDataBound"
      >
        <e-columns>
          <e-column
            :template="destinataryTemplate"
            field="id"
            headerText="Destinario"
            isPrimaryKey="{true}"
          ></e-column>
          <e-column
            headerText="Tipo"
            :template="typeTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText="N Articoli"
            :template="productsCountTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText="Importo"
            :template="totalTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText=""
            :template="actionsTemplate"
            width="200"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>

<script>
import last from "lodash/last";
import get from "lodash/get";
import { Page } from "@syncfusion/ej2-vue-grids";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import { addAndPredicateFilter, formatPrice } from "../../../../helpers/common";
import InvoicesTableDestinatary from "./InvoicesTableDestinatary";
import InvoicesTableType from "./InvoicesTableType";
import InvoicesTableProductsCountSel from "./InvoicesTableProductsCountSel";
import InvoicesTableTotal from "./InvoicesTableTotal";
import InvoicesOutTableActions from "./InvoicesOutTableActions";
import { GetDataManagerNew } from "../../../../ds";

export default {
  name: "InvoicesOutToBeIssued",

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  provide: {
    grid: [Page],
  },

  data() {
    const roleId = this.$store.state.role.id;
    const dm = GetDataManagerNew("role_invoices_toemit", [roleId]);

    return {
      dm: dm,
      query: new Query(),
      pageSettings: {
        pageSize: 10,
        pageSizes: [10, 20, 50],
      },

      // aggregates
      totalAmount: null,

      destinataryTemplate: () => {
        return { template: InvoicesTableDestinatary };
      },

      typeTemplate: () => {
        return { template: InvoicesTableType };
      },

      productsCountTemplate: () => {
        return { template: InvoicesTableProductsCountSel };
      },

      totalTemplate: () => {
        return { template: InvoicesTableTotal };
      },

      actionsTemplate: () => {
        return { template: InvoicesOutTableActions };
      },
    };
  },

  computed: {
    hasAggregatedData() {
      return this.totalAmount !== null;
    },
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    onDataBound() {
      try {
        const dataModule = this.$refs.grid.getDataModule();
        const response = JSON.parse(
          get(
            last(dataModule.dataManager.requests),
            "httpRequest.response",
            null
          )
        );
        if (response) {
          this.totalAmount = get(response, "payload.tot_amount", null);
        }
      } catch (error) {
        console.error(error);
      }
    },

    totalToBeIssued() {
      return this.totalAmount !== null
        ? formatPrice(this.totalAmount, this.currencySymbol).format()
        : "";
    },

    prepareQuery(params) {
      const search = get(params, "search");
      const invoiceType = get(params, "invoiceType");

      let predicate = null;

      if (invoiceType) {
        predicate = addAndPredicateFilter(
          predicate,
          "i_type",
          "equal",
          invoiceType
        );
      }
      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = ["i_to.name"];
      if (search) {
        query = query.search(search, searchFields);
      }

      return query;
    },

    doSearch(params) {
      this.query = this.prepareQuery(params);
    },
  },
};
</script>
