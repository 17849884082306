<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">Fatture in uscita</div></CCol
      >
    </CRow>

    <CCard>
      <CCardBody>
        <InvoicesOutFilters
          ref="filters"
          :filters="filters"
          @onSearch="onSearch(filters)"
        />
        <InvoicesOutNav :view="view" @update:view="onViewChange" />
        <InvoicesOutToBeIssued
          ref="toBeIssued"
          :filters="filters"
          v-show="view === INVOICE_STATUS.TO_BE_ISSUED"
        />
        <InvoicesOutIssued
          ref="issued"
          :filters="filters"
          v-show="view === INVOICE_STATUS.ISSUED"
        />
        <InvoicesEmitModal />
        <InvoicesEditModal />
        <InvoicesCashModal />
        <InvoicesConfirmModal />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import get from "lodash/get";
import { INVOICE_STATUS } from "../../../config/global";
import InvoicesOutFilters from "./partials/InvoicesOutFilters";
import InvoicesOutToBeIssued from "./partials/InvoicesOutToBeIssued";
import InvoicesOutIssued from "./partials/InvoicesOutIssued";
import InvoicesOutNav from "./partials/InvoicesOutNav";
import InvoicesEmitModal from "./share/InvoicesEmitModal";
import InvoicesEditModal from "./share/InvoicesEditModal";
import InvoicesCashModal from "./share/InvoicesCashModal";
import InvoicesConfirmModal from "./share/InvoicesConfirmModal";
import EventBus from "../../../helpers/EventBus";
import subMonths from "date-fns/subMonths";
import { parseInvoicingSettings } from "../../../helpers/common";

export default {
  name: "InvoicesOut",

  components: {
    InvoicesOutFilters,
    InvoicesOutToBeIssued,
    InvoicesOutIssued,
    InvoicesOutNav,
    InvoicesEmitModal,
    InvoicesEditModal,
    InvoicesCashModal,
    InvoicesConfirmModal,
  },

  provide() {
    const invoicingSettings = parseInvoicingSettings(
      get(this.$store.state.role, "extra.invoicing", null)
    );
    return {
      invoicingSettings: invoicingSettings,
    };
  },

  data() {
    return {
      INVOICE_STATUS,
      view: INVOICE_STATUS.TO_BE_ISSUED,
      filters: {
        search: "",
        invoiceType: "",
        start: subMonths(new Date(), 6),
        end: new Date(),
      },
    };
  },

  watch: {
    filters: {
      handler(val) {
        this.onSearch(val);
      },
      deep: true,
    },
  },

  mounted() {
    this.init();
    EventBus.$on("invoices:refresh", this.refresh);
  },

  beforeDestroy() {
    EventBus.$off("invoices:refresh", this.refresh);
  },

  methods: {
    init() {
      const { view } = this.$route.query;

      if (!view) {
        this.$router.push({ query: { view: this.view } });
        this.onViewChange(this.view);
      } else {
        this.onViewChange(view);
      }
    },

    onViewChange(view) {
      this.view = view;
      this.$router.push({ query: { view } }).catch(() => {});

      this.$nextTick(() => {
        this.onSearch(this.filters);
      });
    },

    onSearch(filters) {
      const { view } = this;
      if (view === INVOICE_STATUS.TO_BE_ISSUED) {
        this.$refs.toBeIssued.doSearch(filters);
      }
      if (view === INVOICE_STATUS.ISSUED) {
        this.$refs.issued.doSearch(filters);
      }
    },

    refresh() {
      this.onSearch(this.filters);
    },
  },
};
</script>
